import styles from './Online.module.scss'

export function Online() {
  return (
    <div className={styles.online}>
      <div className={styles.onlineRow}>
        <span className={styles.value}>
          35,197,397
        </span>
        <span className={styles.label}>total players</span>
      </div>
    </div>
  )
}