import { Outlet } from 'react-router-dom'
import { Header } from '..'
import styles from './Layout.module.scss'
import { Stars } from '..'

export function Layout() {
  return (
    <>
      <Stars />

      <Header />
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <Outlet />
        </div>
      </main>
    </>
  )
}
