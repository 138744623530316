import { Button, Online } from '../../components'
import styles from './Home.module.scss'

export function Home() {
  const openNotcoin = () => {
    window.location.href = "https://t.me/notcoin"
  }

  return (
    <main className={styles.container}>
      <div className={styles.heroSection}>
        <h1 className={styles.heroTitle}>Notcoin</h1>
        <h2 className={styles.heroSubtitle}>the biggest community eveeer</h2>
        <Online />
        <Button onClick={openNotcoin}>Join</Button>
      </div>
    </main>
  )
}
