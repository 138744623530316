import { forwardRef, ButtonHTMLAttributes } from 'react'
import styles from './Button.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

function XButton(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  return (
    <button {...props} className={styles.button} ref={ref}>
      {props.children}
    </button>
  )
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(XButton)

// https://www.radix-ui.com/primitives/docs/guides/composition#your-component-must-forward-ref
