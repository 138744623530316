import cn from 'classnames'
import { useIsMobile } from '../../hooks/useIsMobile'
import styles from './Stars.module.scss'

export function Stars() {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <div className={styles.container}>
        <MobileStars />
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <DesktopStars />
      </div>
    )
  }
}

interface StarProps {
  size: 'sm' | 'md' | 'lg' | 'xl'
  top?: string
  bottom?: string
  left?: string
  right?: string
}

function Star({ size, top, bottom, left, right }: StarProps) {
  const style = { top, bottom, left, right }
  let sizeClass: string

  switch (size) {
    case 'sm':
      sizeClass = styles.small
      break
    case 'md':
      sizeClass = styles.medium
      break
    case 'lg':
      sizeClass = styles.large
      break
    case 'xl':
      sizeClass = styles.xlarge
      break
    default:
      sizeClass = styles.medium
      break
  }

  const classes = cn(styles.star, sizeClass)

  return (
    <img src="/small-star.svg" alt="small star" style={style} className={classes} />
  )
}

function DesktopStars() {
  return (
    <>
      {/* left stars */}
      <div className={styles.holderLeft}>
        <Star size="md" top="2.5rem" left="8rem" />
        <Star size="sm" top="20rem" left="6rem" />
        <Star size="sm" top="22rem" left="20rem" />
        <Star size="lg" top="25rem" left="10rem" />
      </div>
      {/* right stars */}
      <div className={styles.holderRight}>
        <Star size="md" top="8rem" right="18rem" />
        <Star size="xl" top="20rem" right="5rem" />
        <Star size="sm" top="23rem" right="7rem" />
        <Star size="md" top="26rem" right="16rem" />
      </div>
    </>
  )
}

function MobileStars() {
  return (
    <>
      {/* left stars */}
      <div className={styles.holderLeft}>
        <Star size="sm" bottom="1rem" left="1rem" />
      </div>
      {/* right stars */}
      <div className={styles.holderRight}>
        <Star size="md" bottom="20rem" right="3rem" />
        <Star size="sm" bottom="19rem" right="1rem" />
        <Star size="sm" top="1.25rem" right="0.5rem" />
        <Star size="md" top="2.5rem" right="3rem" />
        <Star size="sm" top="6rem" right="-0.5rem" />
      </div>
    </>
  )
}
