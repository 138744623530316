import { tg, x } from '../../lib/utils/static'
import styles from './Header.module.scss'
import { Link, useMatch } from 'react-router-dom'

export function Header() {
  const isSquad = useMatch('/:squadSlug')

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <a className={styles.wpLink} href="https://cdn.joincommunity.xyz/notcoin/Notcoin_Whitepaper.pdf">
          Whitepaper
        </a>

        { isSquad ? (
          <Link className={styles.homeLink} to="/">
            Notcoin
          </Link>
        ) : <div /> }

        <div className={styles.socials}>
          <a href="https://twitter.com/thenotcoin">
            <img src={x} alt="X logo" width={16} height={16} />
          </a>
          <a href="https://t.me/notcoin">
            <img src={tg} alt="Telegram logo" width={20} height={20} />
          </a>
        </div>
      </div>
    </header>
  )
}

// 'use client'

// import { usePathname } from "next/navigation"
// import Image from 'next/image'
// import Link from "next/link"
// import QrDialog from "./radix/QrDialog"

// export default function Header() {
//   // const renderBack = false
//   const path = usePathname()

//   const renderBack = path === '/squad'

//   return (
//     <header className="">
//       <div className="">

//         <Link href="/" className="font-semibold">
//           Whitepaper
//         </Link>

//         { renderBack && (
//           <Link href="/" className="font-bold text-2xl">
//             Notcoin
//           </Link>
//         ) }

//         <div className="flex gap-6">
//           <a href="https://twitter.com/thenotcoin">
//             <Image src="/x-logo.svg" alt="X logo" width={16} height={16} />
//           </a>
//           <a href="https://t.me/notcoin">
//             <Image src="/tg-logo.svg" alt="Telegram logo" width={20} height={20} />
//           </a>
//         </div>

//       </div>
//     </header>
//   )
// }
